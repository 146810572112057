<template>
  <div>
    <v-dialog
      ref="dialog"
      v-model="showMeteograms"
      persistent
      min-width="500px"
      width="80vw"
      :fullscreen="mobileBreakpoints()"
    >
      <v-overlay :value="overlay">
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
      <v-card>
        <v-card-title
          v-if="!mobileBreakpoints()"
          fixed
          class="green--text text--accent-4 d-block sticky"
          :class="{ 'scrolled': isScrolling }"
        >
          <div
            class="d-flex justify-space-between"
            style="align-items: center;"
          >
            <p class="ma-0">
              {{ $t('Meteogram.name') }}
            </p>

            <v-btn-toggle
              v-model="chartStep"
              mandatory
              dense
              class="ms-8"
              @change="changeParameters()"
            >
              <v-btn
                v-for="(step, i) in chartStepper"
                :key="i"
                class="text-capitalize"
                outlined
                :disabled="step.disabled"
                color="#0da344"
                @click="selectedStep = step.value"
              >
                {{ step.value }} {{ $t('Meteogram.days') }}
              </v-btn>
            </v-btn-toggle>

            <v-spacer />

            <v-menu
              offset-y
              class="text-center"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#4caf50"
                  small
                  outlined
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ $t('ServiceCard.export') }}
                  <v-icon
                    class="ma-1"
                    color="#4caf50"
                  >
                    mdi-chart-line
                  </v-icon>
                </v-btn>
              </template>
              <v-list-item
                v-for="item in exportChartExtensions"
                :key="item.type"
                style="background-color: white"
                :color="item.color"
                @click="exportChart(item.type)"
              >
                <v-list-item-icon class="mr-1 ml-2">
                  <v-icon
                    :color="item.color"
                  >
                    mdi-file-export
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <span :style="`color: ${item.color}`">{{ item.type }}</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-menu>
            <v-icon
              color="red"
              size="30"
              class="ml-4"
              @click="$emit('close-dialog')"
            >
              mdi-close
            </v-icon>
          </div>
          <div>
            <p class="text-subtitle-2 text-center">
              {{ `${$t('Meteogram.forecastsFor')} ${selectedPoint.location_name} (${selectedPoint.latitude}, ${selectedPoint.longitude}) | ${$t('Meteogram.elevation')}: ${elevation} m | Update: ${update}` }}
            </p>
          </div>
        </v-card-title>
        <v-card-title
          v-else
          fixed
          class="green--text text--accent-4 d-block sticky pa-4"
          :class="{ 'scrolled': isScrolling }"
        >
          <div
            class="d-flex justify-space-between"
            style="width: 100%;"
          >
            <p
              class="ma-0"
              style="font-size: 1.2rem;"
            >
              {{ $t('Meteogram.name') }}
            </p>
            <div>
              <v-menu
                offset-y
                class="text-center"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="#4caf50"
                    small
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ $t('ServiceCard.export') }}
                    <v-icon
                      class="ma-1"
                      color="#4caf50"
                    >
                      mdi-chart-line
                    </v-icon>
                  </v-btn>
                </template>
                <v-list-item
                  v-for="item in exportChartExtensions"
                  :key="item.type"
                  style="background-color: white"
                  :color="item.color"
                  @click="exportChart(item.type)"
                >
                  <v-list-item-icon class="mr-1 ml-2">
                    <v-icon
                      :color="item.color"
                    >
                      mdi-file-export
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span :style="`color: ${item.color}`">{{ item.type }}</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-menu>
              <v-icon
                color="red"
                size="30"
                class="ml-4"
                @click="$emit('close-dialog')"
              >
                mdi-close
              </v-icon>
            </div>
          </div>
          <div
            class="d-flex"
            style="justify-content: center;"
          >
            <v-btn-toggle
              v-model="chartStep"
              mandatory
              dense
              class="mt-4"
              @change="changeParameters()"
            >
              <v-btn
                v-for="(step, i) in chartStepper"
                :key="i"
                class="text-capitalize"
                outlined
                :disabled="step.disabled"
                color="#0da344"
                @click="selectedStep = step.value"
              >
                {{ step.value }} {{ $t('Meteogram.days') }}
              </v-btn>
            </v-btn-toggle>
          </div>
          <div class="mt-4">
            <p
              class="text-subtitle-2 text-center"
            >
              {{ `${$t('Meteogram.forecastsFor')} ${selectedPoint.location_name} (${selectedPoint.latitude}, ${selectedPoint.longitude})` }}
            </p>
            <p
              class="text-subtitle-2 text-center"
              style="margin: 0px!important;"
            >
              {{ `${$t('Meteogram.elevation')}: ${elevation} m | Update: ${update}` }}
            </p>
          </div>
        </v-card-title>

        <v-card-text
          ref="meteogram-charts"
          class="pt-4 mtgr-container"
        >
          <v-alert
            v-model="noLayers"
            text
            border="left"
            type="warning"
            class="mt-3"
            icon="mdi-gesture-tap"
            transition="scale-transition"
          >
            {{ $t('Meteogram.noLayers') }}
          </v-alert>

          <div v-if="renderCharts">
            <div
              v-for="(chart, i) in chartVariables"
              :key="i"
              :style="padding(chart)"
            >
              <div
                v-if="filteredLayers(chart.variable).length > 0"
                class="chart-container mb-8"
              >
                <meteogram-chart
                  :labels="meteogramDataTimes"
                  :layers="filteredLayers(chart.variable)"
                  :left-y-axis-label="yAxisLabels(chart, i, 'Left')"
                  :right-y-axis-label="yAxisLabels(chart, i, 'Right')"
                  :left-y-axis-min-max="chart.minMaxLeft"
                  :right-y-axis-min-max="chart.minMaxRight"
                  :wind-direction="drawWindArrows(chart.variable)"
                  :step="selectedStep"
                  @mouse-entered="chartMouseHover($event)"
                />
                <div
                  v-if="drawWindArrows(chart.variable) && filteredLayers(windDirectionLayer).length > 0"
                  :class="mouseEntered ? 'chart-wrapper chart-opacity' : 'chart-wrapper'"
                >
                  <meteogram-chart
                    :labels="meteogramDataTimes"
                    :layers="filteredLayers(windDirectionLayer)"
                    :wind-direction="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import moment from 'moment-business-days';
import html2canvas from 'html2canvas';
import { METEOGRAM_CONFIG } from '@/constants.js';

export default {
  components: {
    MeteogramChart: () => import('@/components/utils/MeteogramChart.vue')
  },
  props: {
    showMeteograms: {
      type: Boolean,
      default: false
    },
    meteogramObj: {
      type: Object,
      default: () => {}
    },
    selectedPoint: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      selectedStep: 7,
      chartStep: (this.mobileBreakpoints()) ? 0 : 2,
      isScrolling: false,
      overlay: false,
      noLayers: false,
      renderCharts: false,
      mouseEntered: false,
      elevation: 'N/A',
      update: 'N/A',
      runtime: '',
      rangeDate: '',
      exportChartExtensions: [
        { type: 'png', color: '#43a047' },
        { type: 'jpg', color: '#43a047' }
      ],
      chartStepper: [{ value: 3 }, { value: 5 }, { value: 7 }, { value: 15 }],
      lineType: [],
      areaType: [],
      rightYAxis: [],
      meteogramDataLayers: [],
      windDirectionLayer: [],
      meteogramDataTimes: [],
      chartVariables: {},
      meteogramData: {}
    };
  },
  computed: {
    ...mapState(['app_user', 'table_chart', 'geoserver_data']),
    chartWrapper() {
      return 'chart-wrapper' + this.mouseEntered ? ' chart-opacity' : '';
    }
  },
  watch: {
    $route() {}
  },
  async mounted() {
    this.overlay = true;
    this.setup();
    await this.setParameters();
    this.overlay = false;
  },
  methods: {
    ...mapActions(['getElevationAction', 'getWmtsArray', 'getMeteogramLayersDataAction']),
    mobileBreakpoints() {
      return this.$vuetify.breakpoint.name === 'xs';
    },
    setup() {
      this.lineType = METEOGRAM_CONFIG.lineType;
      this.areaType = METEOGRAM_CONFIG.areaType;
      this.rightYAxis = METEOGRAM_CONFIG.rightYAxis;
      this.windDirectionLayer = METEOGRAM_CONFIG.windDirectionLayer;
      this.chartVariables = METEOGRAM_CONFIG.chartVariables;
    },
    yAxisLabels(chart, item, position) {
      const labels = [];
      const meteogram = item.charAt(0).toUpperCase() + item.slice(1);

      if (position === 'Left') {
        labels.push(this.$t(`${meteogram}.yLabel${position}`));

        if (chart.labelLeft.length > 0) {
          labels.push(this.$t(chart.labelLeft[0]));
        }
      }

      if (position === 'Right') {
        chart.labelRight.forEach(e => labels.push(this.$t(e)));
      }

      return labels.map(e => this.$t(e));
    },
    filteredLayers(layers) {
      return this.meteogramDataLayers.filter(e => layers.includes(e.name));
    },
    drawWindArrows(layers) {
      return this.filteredLayers(layers).some(e => this.chartVariables.meteogram4.variable.includes(e.name));
    },
    getWmtsRange() {
      const policyLimits = {
        type: this.meteogramObj.ws_policy[0].date_type,
        past: this.meteogramObj.ws_policy[0].start_date,
        future: this.meteogramObj.ws_policy[0].end_date
      };

      if (policyLimits.type != 'static') {
        const past = moment().subtract(parseInt(policyLimits.past), policyLimits.type.replace('dynamic_', ''));
        const future = moment().add(parseInt(policyLimits.future), policyLimits.type.replace('dynamic_', ''));
        policyLimits.past = past.toISOString();
        policyLimits.future = future.toISOString();
      }

      this.chartStepper.forEach(e => {
        const step = moment().add(e.value, 'days');
        e.disabled = moment(policyLimits.future).isBefore(step, 'day');
      });

      if (this.chartStepper[this.chartStep].disabled) {
        this.chartStep = this.chartStepper.length - 1 - this.chartStepper.slice().reverse().findIndex(obj => !obj.disabled);
      }

      const lowerBound = moment();
      const upperBound = moment().add(this.chartStepper[this.chartStep].value, 'days');

      const lowerBoundPolicy = moment(policyLimits.past).isSameOrBefore(lowerBound, 'day')
        ? lowerBound.toISOString()
        : policyLimits.past;

      const upperBoundPolicy = moment(policyLimits.future).isSameOrAfter(upperBound, 'day')
        ? upperBound.toISOString()
        : policyLimits.future;

      return `${lowerBoundPolicy}/${upperBoundPolicy}`;
    },
    async setParameters() {
      await this.getElevationAction([this.selectedPoint.latitude, this.selectedPoint.longitude]);
      this.elevation = parseFloat(this.table_chart.elevation).toFixed(1);

      if (this.meteogramObj.ws_policy.length > 0) {
        this.noLayers = false;
        const time = this.getWmtsRange();

        const wmtsLayer = this.meteogramObj.ws_policy.some(e => e.layer_name === 'TC2M_HOURLY')
          ? `${this.meteogramObj.ws_name}:TC2M_HOURLY`
          : `${this.meteogramObj.ws_name}:${this.meteogramObj.ws_policy[0].layer_name}`;

        const wmtsParams = {
          layer: wmtsLayer,
          time: time
        };
        await this.getWmtsArray(wmtsParams);

        const xml_document = (new window.DOMParser()).parseFromString(this.geoserver_data.xml_string, 'text/xml');
        xml_document.getElementsByTagName('DimensionDomain').forEach(item => {
          if (item.getElementsByTagName('Domain')[0].previousElementSibling.textContent === 'time') {
            if (item.getElementsByTagName('Domain')[0].textContent !== '') {
              this.rangeDate = item.getElementsByTagName('Domain')[0].textContent;
              this.rangeDate = this.rangeDate.replace('--', '/');
            }
          }

          if (item.getElementsByTagName('Domain')[0].previousElementSibling.textContent === 'RUNTIME') {
            if (this.$route.params.id === 'Forecast') {
              this.update = item.getElementsByTagName('Domain')[0].textContent.split('--')[1];
              this.runtime = this.update;
              this.update = moment(this.update, moment.ISO_8601).format('DD/MM/YYYY HH:mm');
            }
          }
        });

        await this.getLayersData();
      } else {
        this.noLayers = true;
      }
    },
    async getLayersData() {
      const data = {
        time: this.rangeDate,
        username: this.app_user.username,
        workspace: this.meteogramObj.ws_name,
        layers: this.meteogramObj.ws_policy.map(layer => layer.layer_name),
        latitude: this.selectedPoint.latitude,
        longitude: this.selectedPoint.longitude,
        output: 'json',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        service_name: this.meteogramObj.service_name,
        service_id: this.meteogramObj.service_id,
        group: this.$route.params.id,
        runtime: this.runtime
      };

      const dataLayersChunks = [];
      const dataLayersCopy = data.layers;

      data.layers.forEach((_, index) => {
        if (index % 16 === 0) {
          dataLayersChunks.push(data.layers.slice(index, index + 16));
        }
      });

      for await (const layers of dataLayersChunks) {
        data.layers = layers;
        await this.getMeteogramLayersDataAction(data);
        this.meteogramData = { ...this.meteogramData, ...this.table_chart.meteogramData };
      }

      data.layers = dataLayersCopy;
      this.meteogramDataLayers = [];
      this.meteogramDataTimes = this.meteogramData.time.map(time => moment(time, moment.ISO_8601).format('DD/MM/YYYY HH:mm'));

      for (const property in this.meteogramData) {
        if (property !== 'time') {
          const layerName = property.split(':')[1];
          const layerObj = this.meteogramObj.ws_policy.find(layer => layer.layer_name === layerName);

          const obj = {
            name: layerName,
            label: `${this.$t(`${property}_serviceID:${this.meteogramObj.service_id}.label`)}${this.$t(`${property}_serviceID:${this.meteogramObj.service_id}.udm`)}`,
            backgroundColor: layerObj.layer_color,
            borderColor: layerObj.layer_color,
            borderWidth: 1.5,
            data: this.meteogramData[property].map(value => value ? parseFloat(value.toFixed(1)) : value),
            pointRadius: 0,
            type: 'bar',
            yAxisID: 'y-axis-1'
          };

          if (this.windDirectionLayer.includes(layerName)) {
            obj.hidden = true;
          }

          if (this.lineType.concat(this.areaType).includes(layerName)) {
            obj.type = 'line';
            const opacity = this.areaType.includes(layerName) ? 0.2 : 0;
            obj.backgroundColor = this.hexToRgba(layerObj.layer_color, opacity);
            obj.pointStyle = 'circle',
            obj.pointHoverRadius = 5;
            obj.pointHoverBackgroundColor = this.hexToRgba(layerObj.layer_color, 0.3);
          }

          if (this.rightYAxis.includes(layerName)) {
            obj.yAxisID = 'y-axis-2';
          }

          this.meteogramDataLayers.push(obj);
        }
      }

      this.renderCharts = true;
    },
    async changeParameters() {
      this.overlay = true;
      this.renderCharts = false;
      await this.setParameters();
      this.overlay = false;
    },
    hexToRgba(hex, opacity) {
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        let c = hex.substring(1).split('');

        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }

        c = '0x' + c.join('');

        return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(', ')}, ${opacity})`;
      }

      return hex;
    },
    exportChart(type) {
      html2canvas(this.$refs['meteogram-charts'], {
        backgroundColor: '#FFFFFF',
        allowTaint: true
      }).then(canvas => {
        const link = document.createElement('a');
        link.download = `${this.selectedPoint.location_name}_mtgr_Radarmeteo.${type}`;
        link.href = canvas.toDataURL(`image/${type}`);
        link.click();
      });
    },
    chartMouseHover(bool) {
      this.mouseEntered = bool;
    },
    padding(chart) {
      let padding = '';

      if (chart.labelLeft.length === 0) {
        padding += 'padding-left: 21px; ';
      }

      if (chart.labelRight.length === 0) {
        padding += 'padding-right: 72px';
      }

      if (chart.labelRight.length === 1) {
        padding += 'padding-right: 28px';
      }

      return padding;
    }
  }
};
</script>

<style scoped>
.chart-container {
  position: relative;
  height: 400px;
}

.chart-wrapper {
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 8px;
  width: 100%;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.chart-opacity {
  opacity: 0.3;
}

.sticky {
  position: sticky!important;
  padding-bottom: 16px;
  top: 0;
  background-color: #FFF;
  border-bottom: 1px solid rgba(0,0,0,0.12);
  box-shadow: 0px 2px 4px rgba(0,0,0,0.1);
  z-index: 2;
}

.text-subtitle-2 {
  margin: 16px 0px 0px 0px!important;
  color: rgba(0,0,0,.6);
}

@media (max-width: 600px) {
  .mtgr-container {
    padding: 8px 16px!important;
  }

  .mtgr-container > div:nth-child(2) > div {
    padding: 0px!important;
  }

  html::-webkit-scrollbar {
    display: none!important;
  }

  ::-webkit-scrollbar {
    display: none!important;
  }
}
</style>
